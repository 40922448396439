import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Input, Space, Button, message, Tag, Modal, Image, Spin, Form, Select, Row, Col, Typography} from 'antd'
import { SearchOutlined, DownloadOutlined, ReloadOutlined,DatabaseOutlined } from '@ant-design/icons';
import moment from "moment";
import QRCode from 'qrcode'


import { AppContext } from '../App';
const { Title } = Typography;


const ListMachine = () => {
  const { user } = useContext(AppContext);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])
  const [branchName, setBranchName] = useState('')

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [onCheckTransaction, setOnCheckTransaction] = useState(false);
  const [openModalQrcode, setOpenModalQrcode] = useState(false)
  const [openModalController, setOpenModalController] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [modalControllerContent, setModalControllerContent] = useState('')
  const [machineSelected,setMachineSelected] = useState('เครื่องหมายเลข')

  const [form] = Form.useForm();
  const [formBranch] = Form.useForm();
  const [commandSelected, setCommandSelected] = useState('')
  const [machineTime, setMachineTime] = useState(25)
  const [branch, setBranch] = useState([]);
  const [branchSelected, setBranchSelected] = useState('')

  const [loadingStatus, setLoadingStatus] = useState(true)
  const [loadingControl, setloadingControl] = useState(false)

  const [width, setWidth] = useState(window.innerWidth);


  const [messageApi, contextHolder] = message.useMessage();

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
  };



  const getColumnSearchProps = (dataIndex) => ({

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
          style={{
          padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
      >
          <Input
          ref={searchInput}
          placeholder={`ค้นหารหัสเครื่อง`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
              marginBottom: 8,
              display: 'block',
          }}
          />
          <Space>
          <Button
              type="primary"
              icon={<SearchOutlined />}
              size="small"
              style={{
              width: 120,
              }}
              onClick={() => {
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
          >
              Search
          </Button>
          <Button
              onClick={() => {
                  clearFilters
                  handleReset(clearFilters)
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
              size="small"
              style={{
              width: 50,
              }}
          >
              Reset
          </Button>
          </Space>
      </div>
      ),
      filterIcon: (filtered) => (
      <SearchOutlined
          style={{
          color: filtered ? '#000000' : undefined,
          }}
      />
      ),
      onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
      if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
      }
      },
  });
  
  const columns = [
    {
        title: 'เลขเครื่อง',
        width: 80,
        dataIndex: 'machineNo',
        key: 'machineNo',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text}
          </div>
        ),
    },
    {
      title: 'รหัสเครื่อง',
      width: 100,
      dataIndex: 'code',
      key: 'code',
      // fixed: 'left',
      ...getColumnSearchProps('code'),
      render: (text, record) => (
        <div style={{ fontWeight: 600, color: "#3a9bdc" }} onClick={() => controlMachine(record)}>
          { text }
        </div>
      )    
    },
    {
        title: 'ชื่อเครื่อง',
        width: 100,
        dataIndex: 'name',
        key: 'name',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text}
          </div>
        ),
    },
    {
      title: 'ประเภท',
      width: 50,
      dataIndex: 'type',
      key: 'type',
      hidden: true,
      // fixed: 'left',
      render: (text, record) => (
        <div>
          {text === 'washer' ? 'เครื่องซัก' : (text === 'dryer' ? 'เครื่องอบ' : 'เครื่องซักอบ') }
        </div>
      ),
  },
    {
      title: 'ราคา',
      width: 50,
      dataIndex: 'price',
      key: 'price',
      // fixed: 'left',
      render: (text, record) => (
        <div>
          {text}
        </div>
      ),
    },
    {
        title: 'สถานะ',
        width: 50,
        dataIndex: 'statusName',
        key: 'statusName',
        render: (text, record) => (
          <div>
             { record.statusId !== 'NETWORK_ERROR' ? ( record.statusId === 'AVAILABLE' ? <Tag color='green'>{text}</Tag> : <Tag color='orange'>{text}</Tag>) : <Tag color='red'>{text}</Tag>}
          </div>
        ),
    },
    {
      title: 'เวลาคงเหลือ',
      width: 70,
      dataIndex: 'timeRemainingFormat',
      key: 'timeRemainingFormat',
      render: (text, record) => (
        <div>
           { record.statusId === 'IN_USE' ? text : '' }
        </div>
      ),
  },
    {
        title: 'QR CODE',
        width: 50,
        dataIndex: 'code',
        key: 'qrcode',
        align: 'center',
        render: (text, record) => (
          <div>
            { <DownloadOutlined className='buttonDownload' onClick={ () => showQrCodeMachine(record)}/>}
          </div>
        ),
      },
    {
      title: 'การจัดการ',
      key: 'action',
      // fixed: 'right',
      width: 70,
      render: (text, record) => (
        
        <div>
          {<Button type="primary" onClick={() => controlMachine(record)} loading={loadingControl}>ควบคุม</Button>}
        </div>
      ),
    },
  ]

  const fetchData = async (branchId) => {
    if(!branchId){ branchId = ''}
    try {
      const response = await axios.get(`${API_URL}getMachineListBranch/${branchId}`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })

      const adjustedData = response.data.map((machine) => ({
        ...machine,
        key: machine.id,
      }));

      setData(adjustedData)

      form.setFieldsValue({
        command: '',
        machineTime: 25,
      });

      setLoadingStatus(false)

    } catch (error) {
      console.log('error', error)
    }
  }

  const getBranch = async () => {
    try {
        const response = await axios.get(`${API_URL}getBranchManager`,
            {
                headers: { 'Authorization': `bearer ${token}` }
            })
        setBranch(response.data);
        if(response.data.length !== 0){
          setBranchName(response.data[0].name)
          formBranch.setFieldsValue({
            branch: response.data[0]? response.data[0].id : [],
        });
        }

    } catch (error) {
        console.log(error);
    }
  }

  const changeBranchMachine = async (value) => {
    branch.forEach(element => {
        if(element.id === value){
          setBranchName(element.name)
        }
    });
    setLoadingStatus(true)
    setData('')
    setBranchSelected(value)
    fetchData(value)
  }

  const showQrCodeMachine = (machineDetail) => {
    setMachineSelected("เครื่องที่ " + machineDetail.machineNo + " - " +machineDetail.name )

    QRCode.toDataURL(machineDetail.code)
        .then(url => {
            setOpenModalQrcode(true)
            setModalContent(
            <div className='ModalContent'>
              <Image width={200} src={url} preview={false}/>
              <p>หมายเลขเครื่อง: {machineDetail.machineNo}</p>
              <p>รหัสเครื่อง: {machineDetail.code}</p>
              <p>ชื่อเครื่อง: {machineDetail.name}</p>
            </div>
            )
        })
        .catch(err => {
            console.error(err)
        }) 
  }

  const controlMachine = async(machineDetail) => {

     form.setFieldsValue({
      machineCode: machineDetail.code,
      command: '',
      programId: '',
      machineTime: 25,
    });
    setMachineSelected("เครื่องที่ " + machineDetail.machineNo + " - " +machineDetail.name )
    setOpenModalController(true)
    setModalControllerContent(
      <Form
          form={form}
          layout="vertical"
      >

          <Form.Item
              name="machineCode"
              label="รหัสเครื่อง"   
          >
            <Input type="text" disabled />
          </Form.Item>
          <Form.Item
              name="command"
              label="คำสั่ง:"
              rules={[
                {
                    required: true,
                    message: 'กรุณาเลือกคำสั่ง',
                },
            ]}
          >
              <Select>
                { machineDetail.statusId === "AVAILABLE" ? <Select.Option key='start' value='start'>เริ่มทำงาน</Select.Option> : '' }
                { machineDetail.statusId === "IN_USE" && machineDetail.type === 'dryer'? <Select.Option key='extendTime' value='extendTime'>ต่อเวลาอบผ้า</Select.Option> : '' }
                { machineDetail.statusId === "IN_USE" ? <Select.Option key='stop' value='stop'>หยุดทำงาน</Select.Option> : '' }
                { machineDetail.statusId === "READY_TO_START" ? <Select.Option key='rapidAdvanceToReady' value='rapidAdvanceToReady'>ยกเลิกการซัก/อบ</Select.Option> : '' }
                { machineDetail.statusId === "PARTIAL_VEND" ? <Select.Option key='rapidAdvanceToReady' value='rapidAdvanceToReady'>เคลียร์เหรียญในเครื่อง</Select.Option> : '' }
                { machineDetail.statusId !== "COMPLETE" ? <Select.Option key='clearError' value='clearError'>เคลียร์ error</Select.Option> : '' }
              </Select>
          </Form.Item>
          { 
            machineDetail.statusId === "AVAILABLE" && machineDetail.machineBrand === 'tosei' ?
              <Form.Item
                name="programId"
                label="โปรแกรม:"
                rules={[
                  {
                      required: true,
                      message: 'กรุณาโปรแกรม',
                  },
              ]}
            >
                <Select>
                  {machineDetail.machineProgram.map(programOne => (
                      <Select.Option key={programOne.programLaundry.id} value={programOne.programLaundry.id} label={programOne.programLaundry.name}>{programOne.programLaundry.name}</Select.Option>
                  ))}
                </Select>
            </Form.Item>
            : ""
          }
          { 
            machineDetail.statusId === "AVAILABLE" && machineDetail.type === 'dryer' ?
            <Form.Item 
              name="machineTime" 
              label="เวลาอบผ้า:"
              rules={[
                {
                    required: true,
                    message: 'กรุณากำหนดเวลาอบผ้า',
                },
            ]}
            >
              <Input type="number" max={73} min={12} />
            </Form.Item>
            : ""
          }
          { 
            machineDetail.statusId === "IN_USE" && machineDetail.type === 'dryer' ?
            <Form.Item 
              name="machineTime" 
              label="เวลาอบผ้า:"
              rules={[
                {
                    required: true,
                    message: 'กรุณากำหนดเวลาอบผ้า',
                },
            ]}
            >
              <Input type="number" max={24} min={12} />
            </Form.Item>
            : ""
          }
          
      </Form>
    )
  }

  const handleCancelQrcode = () => {
    setOpenModalQrcode(false)

  }

  const handleCancelController = () => {
    form.setFieldsValue({
      command: '',
      machineTime: 25,
    });
    setOpenModalController(false)

  }

  const submitControllerMachine = async (value) => {
  
    setloadingControl(true)

    const machineCodeForm = value.machineCode;
    const commandForm = value.command
    if(value.machineTime){ var machineTime = value.machineTime }else { var machineTime = ''}
    if(value.programId){ var programId = value.programId}else{ var programId = ''}

    try {
      const response = await axios.post(`${API_URL}controlMachine`, {
        machineCode: machineCodeForm,
        machineCommand: commandForm,
        machineTime: machineTime,
        programId:programId
      }, {
          headers: {
              'Authorization': `bearer ${token}`,
              'Content-Type': 'application/json'
          }
      })

      if(response.data.status === 'complete'){
        messageApi.open({
          type: 'success',
          content: 'สั่งงานเครื่องเรียบร้อย',
        });
        fetchData(branchSelected)
      }else if(response.data.status === 'fail'){
        messageApi.open({
          type: 'error',
          content: 'ไม่สามารถสั่งงานได้ กรุณาลองใหม่อีกครั้ง',
        });
        
        fetchData(branchSelected)
      }

    } catch (error) {
      console.log('error', error)
    }

    setloadingControl(false)
  }

  const refreshData = async() => {
    fetchData(branchSelected);
    setLoadingStatus(true)
    setData('')
  }

  useEffect(() => {
    if(data.length === 0 && branch.length === 0){
      getBranch()
      fetchData()
    }
  }, [data])

  return (
    <div className="fetch-event-table">
      {
        <Modal
        title={machineSelected}
        centered
        open={openModalQrcode}
        footer={null}
        width={400}
        onCancel={handleCancelQrcode}
        >
        {modalContent}
        </Modal>
      }
      {
        <Modal
        title={machineSelected}
        centered
        open={openModalController}
        width={400}
        onCancel={handleCancelController}
        onOk={() => {
          form
              .validateFields()
              .then((values) => {
                submitControllerMachine(values)
                form.setFieldsValue({
                  command: '',
                  machineTime: 25,
                })
                setOpenModalController(false);
              })
              .catch((errorInfo) => {
                  console.log('Failed:', errorInfo);
              });
      }}
        >
        {modalControllerContent}
        </Modal>
      }
      <Row>
        <Col span={24} ><Title level={3} style={{textAlign:'center'}}>สาขา: {branchName}</Title></Col>
      </Row>

      <Row>
        <Col span={21} ><Title level={4} ><DatabaseOutlined /> เครื่องซักผ้า</Title> ข้อมูล ณ เวลา {moment().format('DD/MM/YYYY H:mm')} </Col>
        <Col span={3} ><a href="/machineControlHistory"><Title level={3} ><Button type="primary">ประวัติการควบคุม</Button></Title></a> <a href="/machineUsageReport"><Title level={3} ><Button type="primary">รายงานการใช้งาน</Button></Title></a></Col>
      </Row>
      <div style={{display:'flex',alignItems:'center'}}>
      {
        <Form form={formBranch}>
        <Form.Item name="branch" label='สาขา' className='formItemWithNoMargin' >
        <Select style={{width:150}} optionFilterProp="label" onChange={changeBranchMachine}>
            {branch.map(branchItem => (
                <Select.Option key={branchItem.id} value={branchItem.id} label={branchItem.name}>{branchItem.name}</Select.Option>
            ))}
        </Select>
        </Form.Item>
        </Form>

      }
      <p style={{textAlign:'right'}}>&nbsp;&nbsp;&nbsp; <ReloadOutlined onClick={() => refreshData()} /> </p>
      </div>
      <br />
      {contextHolder}
      {data && data.length > 0 ? (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="id"
          scroll={{
            x: 600,
          }}
        />
      ) : (
        loadingStatus ? <center><Spin spinning={loadingStatus}/></center> : 'ไม่มีข้อมูล'
      )}
    </div>
  )
}

export default ListMachine